export const meses = [
  { value: 0, number: "01", name: "Janeiro" },
  { value: 1, number: "02", name: "Fevereiro" },
  { value: 2, number: "03", name: "Março" },
  { value: 3, number: "04", name: "Abril" },
  { value: 4, number: "05", name: "Maio" },
  { value: 5, number: "06", name: "Junho" },
  { value: 6, number: "07", name: "Julho" },
  { value: 7, number: "08", name: "Agosto" },
  { value: 8, number: "09", name: "Setembro" },
  { value: 9, number: "10", name: "Outubro" },
  { value: 10, number: "11", name: "Novembro" },
  { value: 11, number: "12", name: "Dezembro" },
];
